import axios from "axios";
import { saveAudioToDB } from "./database.ts";
import { USE_AZURE_KEY } from "./StorageConst.ts";
import { getBooleanFromLocalStorage } from "./StorageSetter.ts";
import { ChatBotResponse } from "../types.tsx";

const URL = "https://raspberry-8000.thelucid.org/";
// const URL = "http://localhost:8000/";

export async function chatbot_query(query: string): Promise<ChatBotResponse> {
  const formData = new FormData();

  formData.append("query", query);

  if (getBooleanFromLocalStorage(USE_AZURE_KEY)) {
    formData.append("generateAudio", "0");
  } else {
    formData.append("generateAudio", "1");
  }
  try {
    const response = await axios.post(`${URL}chatbot/query`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const { audio_base64 } = response.data;
    await saveAudioToDB(audio_base64);
    return response.data as ChatBotResponse;
  } catch (error) {
    console.error("Error making the GET request", error);
    throw error;
  }
}

export async function chatbot_restart() {
  const response = await axios.get(`${URL}chatbot/restart`);
  return response.data;
}
