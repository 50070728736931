import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

const SectionHeader = ({ title, subtext }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "10%",
      }}
    >
      <Typography sx={{ fontSize: theme.typography.h1 }}>{title}</Typography>
      <Typography sx={{ fontSize: theme.typography.h6 }}>{subtext}</Typography>
    </Box>
  );
};

export default SectionHeader;
