import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import Title from "../Title.tsx";
const HeroText = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        padding: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Title word1={"dylan"} word2={"siew"} variant={"h1"} />

        <Divider />
        <Typography
          sx={{
            fontSize: theme.typography.h6,
          }}
        >
          Full Stack Developer
        </Typography>

        <Typography
          sx={{
            fontSize: theme.typography.h6,
            marginTop: 5,
            color: "grey",
          }}
        >
          Welcome to my site of random projects.
        </Typography>
        <Button
          sx={{
            backgroundColor: theme.palette.primary.dark,
            gap: 2,
            padding: 1,
            marginTop: 5,
          }}
          disableRipple
          variant="contained"
        >
          <Typography sx={{ color: theme.palette.primary.light }}>
            say Hello
          </Typography>
          <SendOutlinedIcon sx={{ color: theme.palette.primary.light }} />
        </Button>
      </Box>
    </Box>
  );
};

export default HeroText;
