import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Header from "../Components/Header/main.tsx";
import { Box, Divider } from "@mui/material";
import HeroSection from "../Components/Hero/main.tsx";
import AboutMeSection from "../Components/AboutMe/main.tsx";
import SkillsSection from "../Components/Skills/main.tsx";
import ProjectSection from "../Components/Projects/main.tsx";
import ContactSection from "../Components/Contact/main.tsx";
import FooterSection from "../Components/Footer/main.tsx";
import WorkExperienceSection from "../Components/WorkExperience/main.tsx";

const SectionCard = ({ children }) => {
  return (
    <Box
      sx={{
        width: "100%",
        scrollSnapAlign: "start",
      }}
    >
      {children}
    </Box>
  );
};
const ContentSection = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 20,
        scrollSnapType: "y proximity",
      }}
    >
      <HeroSection />
      <AboutMeSection />
      <SkillsSection />
      <WorkExperienceSection />
      <ProjectSection />
      <ContactSection />
      <FooterSection />
    </Box>
  );
};
function HomePage({}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          backgroundColor: "white",
          zIndex: 3,
          paddingBottom: 1,
          minHeight: "5vh",
        }}
      >
        <Header />
      </Box>
      <ContentSection />
    </Box>
  );
}

export default HomePage;
