import React, { useState, useRef } from 'react';
import { Typography, Box, alpha, Button, Grid } from '@mui/material';
import { maintheme } from "../../../types.tsx";
import GridCell from './GridCell.tsx';
import { useGame } from '../logic/GameContext.js';
import Pieces from '../logic/Pieces.js';
import AnimationOverlay from './GameStartAnimation.tsx';

export default function Gammegrid({ selectedShip }) {
    const numbers = 10;
    const yn = 10;
    const { player, opponent, handleAction, setPlayer, setOpponent } = useGame();
    const [cellShip, setCellShip] = useState(Array(numbers * yn).fill(null));
    const [gameState, setGameState] = useState(false);
    const [showAnimation, setShowAnimation] = useState(false);

    function handleSelect(index) {
        if (cellShip[index] === null && selectedShip?.placeNew(index)) {
            setCellShip((prevCellShip) => {
                const newCellShip = [...prevCellShip];
                newCellShip[index] = selectedShip;
                return newCellShip;
            });
        }
    }
    function handleReset() {
        setCellShip(Array(numbers * yn).fill(null));
        setPlayer(new Pieces());
        setOpponent(new Pieces());
    }

    const handleAnimationComplete = () => {
        setShowAnimation(false);
    };

    const startGame = () => {
        player.pieces.map((piece) => {
            if (piece.placed.length !== piece.size) {
                return;
            }
        });
        setGameState(true);
        setShowAnimation(true); 
    };

    return (
        <Box
            sx={{ display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: '100%',
            justifyContent: 'center' }}
        >

            <Grid container 
                style={{
                    padding: "20px",
                    width: '50%',
                    aspectRatio: 1
                }}
            >
                {cellShip?.map((piece, index) => {
                    return (<Grid item xs={12/10}>
                        <GridCell key={index} handleSelect={handleSelect} index={index} cellShip={cellShip[index]} piece={piece} />
                        </ Grid>)
                }
                )}
            </Grid>
            <Box>
                <Button
                    sx={{ width: "auto", margin: "0 auto", backgroundColor: '#A7D489', borderRadius: 3, mr: 3 }}
                    onClick={startGame}
                    disableRipple
                >
                    <Typography sx={{
                        color: maintheme.palette.primary.dark,
                        fontSize: '30px'
                    }}>Start Game</Typography>
                </Button>
                <Button
                    sx={{ width: "auto", margin: "0 auto", backgroundColor: maintheme.palette.secondary.main, borderRadius: 3, mr: 3 }}
                    onClick={handleReset}
                    disableRipple
                >
                    <Typography sx={{
                        color: maintheme.palette.primary.dark,
                        fontSize: '30px'
                    }}>Reset Game</Typography>
                </Button>
                <Button
                    sx={{ width: "auto", margin: "0 auto", backgroundColor: '#FF6961', borderRadius: 3 }}
                    onClick={handleReset}
                    disableRipple
                >
                    <Typography sx={{
                        color: maintheme.palette.primary.dark,
                        fontSize: '30px'
                    }}>End Game</Typography>
                </Button>
            </Box>

        </Box>
    );
}
