import React, { useEffect, useRef } from "react";
import { useAnimations, useFBX, useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useAudio } from "../../../utils/Context/AudioContext.tsx";
import { useVisemes } from "../../../utils/Context/VisemeContext.tsx";
import { visemeMap } from "./VisemeMapping.ts";
import { MathUtils } from "three";

export function Avatar(props) {
  const group = useRef();
  const { nodes, materials, scene } = useGLTF("models/dylanavatar.glb");
  const { animations: typingAnimation } = useFBX("animations/Typing.fbx");
  const { animations: sittingAnimation } = useFBX("animations/Sitting2.fbx");
  const { animations: talkingAnimation } = useFBX(
    "animations/Sitting Talking.fbx"
  );
  const { animations: standToSit } = useFBX("animations/Stand To Sit.fbx");
  const { audio } = useAudio();
  const { visemes } = useVisemes();
  typingAnimation[0].name = "Typing";
  sittingAnimation[0].name = "Sitting";
  talkingAnimation[0].name = "Talking";
  standToSit[0].name = "Stand To Sit";
  const { actions } = useAnimations(
    [
      ...typingAnimation,
      ...sittingAnimation,
      ...talkingAnimation,
      ...standToSit,
    ],
    group
  );

  const lerpTarget = (target, value, speed = 0.1) => {
    if (nodes.Wolf3D_Head.morphTargetDictionary[target] !== undefined) {
      nodes.Wolf3D_Head.morphTargetInfluences[
        nodes.Wolf3D_Head.morphTargetDictionary[target]
      ] = MathUtils.lerp(
        nodes.Wolf3D_Head.morphTargetInfluences[
          nodes.Wolf3D_Head.morphTargetDictionary[target]
        ],
        value,
        speed
      );
    }
    if (nodes.Wolf3D_Teeth.morphTargetDictionary[target] !== undefined) {
      nodes.Wolf3D_Teeth.morphTargetInfluences[
        nodes.Wolf3D_Teeth.morphTargetDictionary[target]
      ] = MathUtils.lerp(
        nodes.Wolf3D_Teeth.morphTargetInfluences[
          nodes.Wolf3D_Teeth.morphTargetDictionary[target]
        ],
        value,
        speed
      );
    }
  };

  const incrementMorph = (morphName, targetValue, duration) => {
    const stepTime = 5; // Interval between steps in ms
    const steps = duration / stepTime;
    const increment = targetValue / steps;

    let currentValue = 0;

    const incrementStep = () => {
      if (currentValue < targetValue) {
        currentValue += increment;
        if (currentValue > targetValue) {
          currentValue = targetValue;
        }
        if (nodes.Wolf3D_Head.morphTargetDictionary[morphName] !== undefined) {
          nodes.Wolf3D_Head.morphTargetInfluences[
            nodes.Wolf3D_Head.morphTargetDictionary[morphName]
          ] = currentValue;
        }
        if (nodes.Wolf3D_Teeth.morphTargetDictionary[morphName] !== undefined) {
          nodes.Wolf3D_Teeth.morphTargetInfluences[
            nodes.Wolf3D_Teeth.morphTargetDictionary[morphName]
          ] = currentValue;
        }
        setTimeout(incrementStep, stepTime);
      }
    };
    incrementStep();
  };

  const morphFunction = (morphName, value, duration = 0) => {
    if (duration > 0) {
      incrementMorph(morphName, value, duration);
    } else {
      if (nodes.Wolf3D_Head.morphTargetDictionary[morphName] !== undefined) {
        nodes.Wolf3D_Head.morphTargetInfluences[
          nodes.Wolf3D_Head.morphTargetDictionary[morphName]
        ] = value;
      }
      if (nodes.Wolf3D_Teeth.morphTargetDictionary[morphName] !== undefined) {
        nodes.Wolf3D_Teeth.morphTargetInfluences[
          nodes.Wolf3D_Teeth.morphTargetDictionary[morphName]
        ] = value;
      }
    }
  };

  function updateViseme(timestamp) {
    Object.values(visemeMap).map((e) => {
      morphFunction(e, 0, 0);
    });
    for (let i = visemes.length - 1; i >= 0; i--) {
      if (timestamp * 1000 >= visemes[i].stopTime) {
        morphFunction(visemes[i].readyPlayerMeViseme, 1, 0);
        break;
      }
    }
  }
  useEffect(() => {
    if (audio) {
      audio.play().then((e) => {
        Object.values(visemeMap).map((e) => {
          morphFunction(e, 0, 0);
        });
      });
    }
  }, [audio]);

  useFrame(() => {
    if (audio && !audio.paused && audio.currentTime) {
      updateViseme(audio.currentTime);
    }
  });
  useEffect(() => {
    actions["Typing"].reset().play();
    // Store the external morph function
    // nodes.Wolf3D_Head.morphTargetInfluences[
    //   nodes.Wolf3D_Head.morphTargetDictionary["eyeBlinkLeft"]
    // ] = 1;
    // nodes.Wolf3D_Head.morphTargetInfluences[
    //   nodes.Wolf3D_Head.morphTargetDictionary["tongueOut"]
    // ] = 1;
    // console.log(nodes.Wolf3D_Head.morphTargetDictionary);

    const blinkInterval = 5000;
    const blinkDuration = 200;

    const startBlinking = () => {
      setInterval(() => {
        lerpTarget("eyeBlinkLeft", 1, 0.7);
        lerpTarget("eyeBlinkRight", 1, 0.7);

        setTimeout(() => {
          morphFunction("eyeBlinkLeft", 0);
          morphFunction("eyeBlinkRight", 0);
        }, blinkDuration);
      }, blinkInterval);
    };

    startBlinking();

    // Cleanup function
    return () => clearInterval(startBlinking);
  }, [nodes, actions]);

  return (
    <group {...props} ref={group} dispose={null} position={[0, -0.5, -1]}>
      <group>
        <primitive object={nodes.Hips} />
        <skinnedMesh
          name="EyeLeft"
          geometry={nodes.EyeLeft.geometry}
          material={materials.Wolf3D_Eye}
          skeleton={nodes.EyeLeft.skeleton}
          morphTargetDictionary={nodes.EyeLeft.morphTargetDictionary}
          morphTargetInfluences={nodes.EyeLeft.morphTargetInfluences}
        />
        <skinnedMesh
          name="EyeRight"
          geometry={nodes.EyeRight.geometry}
          material={materials.Wolf3D_Eye}
          skeleton={nodes.EyeRight.skeleton}
          morphTargetDictionary={nodes.EyeRight.morphTargetDictionary}
          morphTargetInfluences={nodes.EyeRight.morphTargetInfluences}
        />
        <skinnedMesh
          name="Wolf3D_Head"
          geometry={nodes.Wolf3D_Head.geometry}
          material={materials.Wolf3D_Skin}
          skeleton={nodes.Wolf3D_Head.skeleton}
          morphTargetDictionary={nodes.Wolf3D_Head.morphTargetDictionary}
          morphTargetInfluences={nodes.Wolf3D_Head.morphTargetInfluences}
        />
        <skinnedMesh
          name="Wolf3D_Teeth"
          geometry={nodes.Wolf3D_Teeth.geometry}
          material={materials.Wolf3D_Teeth}
          skeleton={nodes.Wolf3D_Teeth.skeleton}
          morphTargetDictionary={nodes.Wolf3D_Teeth.morphTargetDictionary}
          morphTargetInfluences={nodes.Wolf3D_Teeth.morphTargetInfluences}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Hair.geometry}
          material={materials.Wolf3D_Hair}
          skeleton={nodes.Wolf3D_Hair.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Glasses.geometry}
          material={materials.Wolf3D_Glasses}
          skeleton={nodes.Wolf3D_Glasses.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Outfit_Top.geometry}
          material={materials.Wolf3D_Outfit_Top}
          skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
          material={materials.Wolf3D_Outfit_Bottom}
          skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
          material={materials.Wolf3D_Outfit_Footwear}
          skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
        />
        <skinnedMesh
          geometry={nodes.Wolf3D_Body.geometry}
          material={materials.Wolf3D_Body}
          skeleton={nodes.Wolf3D_Body.skeleton}
        />
      </group>
    </group>
  );
}

useGLTF.preload("models/dylanavatar.glb");
