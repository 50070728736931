import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

const NavigationButton = () => {};
const ButtonContainer = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <Box sx={{ borderColor: "black", borderBottom: 2 }}>
        <Typography>Home</Typography>
      </Box>
      <Box>
        <Typography>About</Typography>
      </Box>
      <Box>
        <Typography>Projects</Typography>
      </Box>
      <Box>
        <Typography>Skills</Typography>
      </Box>
      <Box>
        <Typography>Contact</Typography>
      </Box>
    </Box>
  );
};

export default ButtonContainer;
