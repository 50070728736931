import React, { createContext, useContext, useState } from 'react';
import Pieces from './Pieces.js';
const GameContext = createContext();

export function useGame() {
  return useContext(GameContext);
}

export const GameProvider = ({ children }) => {
  const [player, setPlayer] = useState(new Pieces());
  const [opponent, setOpponent] = useState(new Pieces());

  // Initialize the game
  // opponent.board.placeRandomly();

  // Define any actions your components need to perform
  const handleAction = (x, y) => {
    console.log("Action");
  };

  // Provide the state and actions to children
  const value = {
    player,
    opponent,
    handleAction,
    setPlayer, 
    setOpponent
  };

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};
