import React, { useState, useEffect } from "react";
import { VOICE_KEY } from "../../utils/StorageConst.ts";
import { DEFAULT_VOICE } from "../../utils/DefaultConst.ts";

function useTextToSpeech() {
  const [utterance, setUtterance] = useState(null);
  const [isReading, setIsReading] = useState(false);
  const [voicesLoaded, setVoicesLoaded] = useState(false);

  useEffect(() => {
    const loadVoices = () => {
      const voices = speechSynthesis.getVoices();
      if (voices.length !== 0) {
        setVoicesLoaded(true);
      }
    };

    loadVoices();
    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = loadVoices;
    }
  }, []);

  const start = (text) => {
    if (!voicesLoaded) {
      console.log("Voices not loaded yet");
      return;
    }

    console.log("Received: ", text);
    let newUtterance = new SpeechSynthesisUtterance(text);
    newUtterance.lang = "en-US";
    newUtterance.pitch = "1";
    newUtterance.rate = 1;
    newUtterance.volume = 0.5;
    newUtterance.voice = getVoiceObj()[0];
    newUtterance.onboundary = function (event) {
      console.log(event)
      if (event.name === "word") {
        console.log("Speaking word:", event.charIndex);
      }
    };
    newUtterance.onend = function () {
      setIsReading(false);
    };
    setUtterance(newUtterance);
    speechSynthesis.speak(newUtterance);
    setIsReading(true);
  };

  const stop = () => {
    if (utterance) {
      speechSynthesis.cancel();
      setIsReading(false);
    }
  };

  const getVoiceObj = () => {
    let chosenVoice = localStorage.getItem(VOICE_KEY) || DEFAULT_VOICE;
    let voices = speechSynthesis.getVoices();
    return voices.filter((e) => e.name === chosenVoice);
  };

  return {
    start,
    stop,
    isReading,
  };
}

export default useTextToSpeech;
