import { createTheme } from "@mui/material/styles";

export const maintheme = createTheme({
  palette: {
    primary: {
      main: "#FF0000",
      light: "#FFFFFF",
      dark: "#000000",
    },
    secondary: {
      main: "#FFFFFF",
      light: "#FFFFFF",
    },
    info: {
      main: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "CrunchChips, Grobold",
    fontWeightBold: 700,
    fontSize: 14,
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
    button: {
      fontWeight: 700,
      fontSize: "30px",
    },
  },
  shape: {
    borderRadius: 6,
  },
  breakpoints: {
    values: {
      xs: 0, // small phone
      sm: 600, // phone
      md: 1200, // tablet
      lg: 1200, // small laptop
      xl: 1536, // desktop
      // You can also add more custom breakpoints if needed
    },
  },
});

export function hexToRGBA(hex, alpha = 1) {
  let r = 0,
    g = 0,
    b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  // 6 digits
  else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const styles = {
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: maintheme.palette.primary.main,
      borderRadius: "10px",
    },
  },
};

export interface Viseme {
  stopTime: number;
  readyPlayerMeViseme: string;
}

export interface ChatBotResponse {
  subtitle: string;
  viseme?: Viseme[];
}
