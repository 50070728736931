import { openDB } from 'idb';
import { AUDIO_KEY } from './StorageConst.ts';

const DB_NAME = 'audio-cache';
const STORE_NAME = 'audios';

export const initDB = async () => {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
};

export const saveAudioToDB = async (base64: string) => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  await store.put(base64, AUDIO_KEY);
  await tx.done;
};

export const getAudioFromDB = async (): Promise<string | undefined> => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  const base64 = await store.get(AUDIO_KEY);
  await tx.done;
  return base64;
};
