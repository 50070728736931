import {
  EmailOutlined,
  FacebookOutlined,
  Instagram,
  LinkedIn,
} from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import Title from "../Title.tsx";

const FooterSection = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        scrollSnapAlign: "end",
        gap: 5,
        paddingBottom: 5,
      }}
    >
      <Box
        sx={{
          width: "60%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
       <Title word1={"dylan"} word2={"siew"} variant={"h1"}/>
      </Box>
      <Box
        sx={{
          width: "50%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <EmailOutlined
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: "white",
            padding: 2,
            borderRadius: 20,
          }}
          fontSize="medium"
        />
        <Instagram
          sx={{
            backgroundColor: "#dd2a7b",
            color: "white",
            padding: 2,
            borderRadius: 20,
          }}
          fontSize="medium"
        />
        <LinkedIn
          sx={{
            backgroundColor: "#0072b1",
            color: "white",
            padding: 2,
            borderRadius: 20,
          }}
          fontSize="medium"
        />
      </Box>
      <Box>
        <Typography variant="h3">All made by yours truly</Typography>
      </Box>
    </Box>
  );
};

export default FooterSection;
