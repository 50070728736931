export function setBooleanInLocalStorage(key: string, value: boolean): void {
  localStorage.setItem(key, value.toString());
}

export function getBooleanFromLocalStorage(key: string): boolean | null {
  const value = localStorage.getItem(key);
  if (value === null) {
    return null;
  }
  return value === "true";
}
