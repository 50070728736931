import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { chatbot_restart } from "../../../utils/api.ts";

const RestartChatbotSetting = () => {
  const theme = useTheme();

  function handleRestartButtonPress(){
    chatbot_restart()
    window.location.reload()
  }
  return (
    <Box>
      <Button
        sx={{
          backgroundColor: theme.palette.primary.main,
          gap: 2,
          padding: 1,
        }}
        disableRipple
        variant="contained"
        onClick={handleRestartButtonPress}
      >
        <Typography sx={{ color: theme.palette.primary.light }}>
          Restart Chatbot
        </Typography>
      </Button>
    </Box>
  );
};

export default RestartChatbotSetting;
