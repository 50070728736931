import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import HeroText from "./HeroText.tsx";
import HeroImage from "./HeroImage.tsx";
import HeroScrollDownButton from "./HeroScrollButton.tsx";

const HeroSection = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "95vh",
        scrollSnapAlign: "start",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          minHeight: "90%",
        }}
      >
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <HeroText />
        </Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <HeroImage />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: "10%",
          justifyContent: "center",
        }}
      >
        <HeroScrollDownButton />
      </Box>
    </Box>
  );
};

export default HeroSection;
