import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { EMAIL } from "../../utils/DefaultConst.ts";

const ContactForm = () => {
  const theme = useTheme();

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleEmail = (event) => {
    event.stopPropagation(); // Prevents event bubbling
    const subject = `I am ${name}, lets connect!`
    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${EMAIL}&su=${subject}&body=${message}`;
    console.log(mailtoLink);
    window.location.href = mailtoLink;
  };
  
  return (
    <Box
      sx={{
        width: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 5,
      }}
    >
      <Typography variant="h2">Reach out to me</Typography>
      <TextField
        id="outlined-basic"
        label="Your name"
        variant="outlined"
        sx={{ width: "80%" }}
        value={name}
        onChange={handleNameChange}
      />

      <TextField
        id="outlined-multiline-static"
        label="Share with me your thoughts"
        variant="outlined"
        sx={{ width: "80%" }}
        rows={10}
        multiline
        value={message}
        onChange={handleMessageChange}
      />
      <Box sx={{ width: "80%" }}>
        <Button
          sx={{
            backgroundColor: theme.palette.primary.dark,
            gap: 2,
            padding: 1,
          }}
          disableRipple
          variant="contained"
          onClick={handleEmail}
        >
          <Typography sx={{ color: theme.palette.primary.light }}>
            Send Message
          </Typography>
          <SendOutlinedIcon sx={{ color: theme.palette.primary.light }} />
        </Button>
      </Box>
    </Box>
  );
};

export default ContactForm;
