import { Box } from "@mui/material";
import React, { useState } from "react";
import VideoSection from "../Components/PantryPals/VideoSection.tsx";
import DescriptionSection from "../Components/PantryPals/DescriptionSection.tsx";

const PantryPalsPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: window.innerHeight,
      }}
    >
      <VideoSection />
      <Box
        sx={{
          width: "100%",
          flexDirection: "row",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <DescriptionSection />
      </Box>
    </Box>
  );
};

export default PantryPalsPage;
