import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { HERO_AVATAR_IMG } from "../../assets/index.tsx";

const HeroImage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        padding: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 5,
      }}
    >
      <img src={HERO_AVATAR_IMG} style={{ width: "50%", borderRadius: 20 }} />
    </Box>
  );
};

export default HeroImage;
