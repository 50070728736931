import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import ChatbotSection from "../Components/Chatbot/main.tsx";
import AvatarCard from "../Components/Chatbot/main.jsx";

const ChatbotPage = () => {
  return <AvatarCard />;
};

export default ChatbotPage;
