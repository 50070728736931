import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import SectionHeader from "../SectionHeader.tsx";
import ContactContainer from "./ContactContainer.tsx";

const ContactSection = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <SectionHeader title={"Contact Me"} subtext={"Get in touch"} />
      <ContactContainer />
    </Box>
  );
};

export default ContactSection;
