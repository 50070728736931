import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { GoogleLoginButton } from "./GoogleOAuthButton";

const LoginPopUp = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        position: "fixed",
        zIndex: 10,
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.7)",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.light,
          width: "90%",
          height: "90%",
          borderRadius: 3,
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <GoogleLoginButton />
      </Box>
    </Box>
  );
};

export default LoginPopUp;
