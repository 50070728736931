import {
  Box,
  Table,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import ModalCard, { ModalCardProps } from "./ModalCard.tsx";
import React from "react";
import { styles } from "../../../types.tsx";
import {
  QCP_CAROUSEL_1_IMG,
  QCP_CAROUSEL_2_IMG,
} from "../../../assets/index.tsx";

const ModalQCP: React.FC<ModalCardProps> = () => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "scroll",
          scrollSnapType: "y mandatory",
          gap: 5,
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        <img
          src={QCP_CAROUSEL_1_IMG}
          style={{ width: "80%", scrollSnapAlign: "center" }}
        />
        <img
          src={QCP_CAROUSEL_2_IMG}
          style={{ width: "80%", scrollSnapAlign: "center" }}
        />
        {/* <img
          src="https://placehold.co/600x400"
          style={{ width: "80%", scrollSnapAlign: "center" }}
        />
        <img
          src="https://placehold.co/600x400"
          style={{ width: "80%", scrollSnapAlign: "center" }}
        />
        <img
          src="https://placehold.co/600x400"
          style={{ width: "80%", scrollSnapAlign: "center" }}
        />
        <img
          src="https://placehold.co/600x400"
          style={{ width: "80%", scrollSnapAlign: "center" }}
        /> */}
      </Box>
      <Box
        sx={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          overflowY: "scroll",
          ...styles.scrollbar,
        }}
      >
        <Typography variant="h1">QCP Capital</Typography>
        <Table sx={{ width: "80%" }}>
          <TableRow>
            <TableCell>
              <Typography variant="h3">Position:</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">Cybersecurity Analyst</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h3">Duration:</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h3">May 2023 - August 2023</Typography>
            </TableCell>
          </TableRow>
        </Table>
        <Typography variant="h2">Experience</Typography>
        <Typography variant="h6" sx={{ width: "90%" }}>
          <ol>
            <li>
              Facilitated and managed the day-to-day IT operations within the
              organization, ensuring smooth and efficient functionality of all
              IT-related activities.
            </li>
            <li>
              Supported the maintenance and development of IT capabilities,
              significantly improving internal processes by 60% through
              strategic enhancements and optimizations.
            </li>
            <li>
              Orchestrated the seamless integration of new IT solutions across
              various departments, strengthening the company's cyber resilience
              and enhancing overall security posture.
            </li>
            <li>
              Formulated, developed, and published 10 comprehensive Governance,
              Risk, and Compliance (GRC) policies, establishing a robust
              framework for managing IT risks and ensuring regulatory
              compliance.
            </li>
            <li>
              Led the design, development, and implementation of sophisticated
              IT solutions tailored to meet the stringent requirements of the
              Monetary Authority of Singapore (MAS).
            </li>
            <li>
              Initiated, organized, and executed three extensive cybersecurity
              awareness programs, fostering a security-oriented culture within
              the organization and enhancing employees' understanding of
              cybersecurity best practices.
            </li>
          </ol>
        </Typography>
      </Box>
    </>
  );
};

export default ModalQCP;
