import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { ABOUT_ME_AVATAR_IMG } from "../../assets/index.tsx";

const AboutMeImage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        maxWidth: "100%",
        padding: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: "100%",
      }}
    >
      <img
        src={ABOUT_ME_AVATAR_IMG}
        style={{ width: "70%", borderRadius: 20 }}
      />
    </Box>
  );
};

export default AboutMeImage;
