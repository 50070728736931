import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import {ProjectContainer} from "./ProjectContainer.tsx";
import SectionHeader from "../SectionHeader.tsx";

const ProjectSection = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <SectionHeader title={"My Projects"} subtext={"My collection of ideas"} />
      <ProjectContainer />
    </Box>
  );
};

export default ProjectSection;
