import { useTheme } from "@emotion/react";
import "./App.css";
import React, { useState, useRef } from "react";
import GamePage from "./Projects/BattleShip/ui/GamePage.tsx";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import { Box, Typography, useMediaQuery } from "@mui/material";
import HomePage from "./Pages/Home.tsx";
import ChatbotPage from "./Pages/ChatBot.tsx";
import PantryPalsPage from "./Pages/PantryPals.tsx";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { G_OATH_CLIENT_ID } from "./utils/DefaultConst.ts";
import { AudioProvider } from "./utils/Context/AudioContext.tsx";
import { VisemeProvider } from "./utils/Context/VisemeContext.tsx";

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/battleship",
      element: <GamePage />,
    },
    {
      path: "/chatbot",
      element: (
        <GoogleOAuthProvider clientId={G_OATH_CLIENT_ID}>
          <AudioProvider>
            <VisemeProvider>
              <ChatbotPage />
            </VisemeProvider>
          </AudioProvider>
        </GoogleOAuthProvider>
      ),
    },
    {
      path: "/pantrypals",
      element: <PantryPalsPage />,
    },
  ]);

  if (isMobile) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography>Opps, not ready for mobile</Typography>
      </Box>
    );
  }
  return <RouterProvider router={router} />;
}

export default App;
