import { Box, Table, TableCell, TableRow, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import Title from "../Title.tsx";
import { PANTRYPAL_ARCH_DIAG_IMG } from "../../assets/index.tsx";

const ListItem = ({ title, description }) => {
  return (
    <li style={{ fontSize: 25 }}>
      <Box sx={{ marginBottom: 3 }}>
        <Typography variant="h3">{title}</Typography>
        <ul>
          <li>
            <Typography>{description}</Typography>
          </li>
        </ul>
      </Box>
    </li>
  );
};
const DescriptionSection = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  return (
    <Box
      sx={{
        width: "70%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        paddingTop: 5,
        gap: 5,
      }}
    >
      <Title word1={"Pantry"} word2={"Pals"} variant={"h1"} />
      <Table>
        <TableRow>
          <TableCell>
            <Typography variant="h2">Pitch</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h3">
              Introducing "PantryPals"! Snap a pic of your groceries or choose
              from your album to upload to your virtual pantry with automatic
              categorization and expiration tagging by AI. Food nearing
              expiration? Opt to share with the community to minimize food
              wastage especially with the large carbon footprint of Singapore’s
              imported ingredients. Browse your inventory or the community's,
              prioritized by proximity and expiration date. Build baskets of
              ingredients, get AI curated recipe suggestions specific to your
              dietary preferences, and even request shared items from the
              community for your culinary adventures. PantryPals: Your smart
              solution for efficient grocery management and community-driven
              food sharing. <br /><br/>Snap a pic of your groceries or choose from your
              album to upload to your virtual pantry with automatic
              categorization and expiration tagging by AI. Browse your inventory
              or the community's, prioritized by proximity and expiration date.
              Build baskets of ingredients, get AI curated recipe suggestions
              specific to your dietary preferences, and even request shared
              items from the community for your culinary adventures.
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="h2">Target Users</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="h3">
              Individuals and families seeking efficient grocery management,
              meal planning solutions, and community-driven food sharing to
              reduce waste and enhance sustainability.
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="h2">Pain Points</Typography>
          </TableCell>
          <TableCell>
            <Typography>
              <ol>
                <ListItem
                  title="Food Waste Management"
                  description="Many users struggle with managing food inventory, leading to significant food wastage."
                />
                <ListItem
                  title="Inventory Overload"
                  description="Keeping track of numerous grocery items can be overwhelming."
                />
                <ListItem
                  title="Meal Planning"
                  description="Users often find it challenging to plan meals based on the ingredients they have."
                />
                <ListItem
                  title="Unorganized Community Sharing"
                  description="Informal methods of sharing food within communities are often disorganized and inefficient."
                />
                <ListItem
                  title="Difficulty in Sourcing Ingredients"
                  description="Finding specific ingredients for recipes can be time-consuming and challenging."
                />
                <ListItem
                  title="Expired Food"
                  description="Users frequently end up with expired food they forgot about."
                />
              </ol>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="h2">Use case</Typography>
          </TableCell>
          <TableCell>
            <Typography>
              <ol>
                <ListItem
                  title={"Image Capture and Selection"}
                  description={
                    "User can capture images of food/groceries or choosepre-captured images from the album."
                  }
                />
                <ListItem
                  title={"AI Classification and Adjustment"}
                  description={
                    "User can send images to the cloud for AI classification, which estimates the quantity and types of food with expected expiration dates. Users can adjust the classification, quantity, and expiration dates, and add missing items."
                  }
                />
                <ListItem
                  title={"Inventory Management"}
                  description={
                    "User can save or append to their inventory list and manually override items for adoption."
                  }
                />
                <ListItem
                  title={"Automated Adoption"}
                  description={
                    "System automatically puts food up for adoption to community users three days before expiration and notifies users of status changes."
                  }
                />
                <ListItem
                  title={"Inventory Viewing"}
                  description={
                    "User can view their inventory and the community's inventory, categorized broadly and ranked by expiration date and distance. Community images are shown in a carousel style."
                  }
                />
                <ListItem
                  title={"Recipe Generation"}
                  description={
                    "User can add items to a basket and generate recipes with step-by-step instructions. Optionally, voice-over instructions can be provided."
                  }
                />
                <ListItem
                  title={"Community Interaction"}
                  description={
                    "User can request food from others when adding community items to the basket, with a simple chat feature for communication."
                  }
                />
                <ListItem
                  title={"Profile Configuration"}
                  description={
                    "User can set their location and choose a profile avatar from pre-set options."
                  }
                />
              </ol>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="h2">Tech stack</Typography>
          </TableCell>
          <TableCell>
            <img src={PANTRYPAL_ARCH_DIAG_IMG} style={{ width: "80%" }} />
            <TableCell>
              <Typography>
                <ul>
                  <ListItem
                    title="Frontend"
                    description="React Native with Expo"
                  />
                  <ListItem title="Backend Server" description="Fastapi" />
                  <ListItem
                    title="Database"
                    description="Mongo DB Atlas and VectorDB"
                  />
                </ul>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <ul>
                  <ListItem
                    title="LLM"
                    description="OpenAI GPT-4o and Gemini-Pro"
                  />
                  <ListItem title="Authentication" description="JWT Tokens" />
                </ul>
              </Typography>
            </TableCell>
          </TableCell>
        </TableRow>
      </Table>
    </Box>
  );
};

export default DescriptionSection;
