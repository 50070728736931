import { SvgIconComponent } from "@mui/icons-material";
import {
  Box,
  IconClasses,
  SvgIconClassKey,
  SvgIconTypeMap,
  Typography,
  useTheme,
} from "@mui/material";
import React, { ReactNode } from "react";

interface IconBadgeWithTextProps {
  icon?: SvgIconComponent;
  children: ReactNode;
  width: string;
  onClick?: (event: any) => void;
}

const IconBadgeWithText: React.FC<IconBadgeWithTextProps> = ({
  icon: Icon,
  children,
  width,
  onClick,
}) => {
  
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: "transparent",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.4)",
        borderRadius: 5,
        width: width,
        cursor: onClick ? "pointer" : "auto",
      }}
      onClick={onClick}
    
    >
      {Icon && <Icon fontSize="large" sx={{}} />}
      {children}
    </Box>
  );
};

export default IconBadgeWithText;
