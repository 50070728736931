import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import {
  getBooleanFromLocalStorage,
  setBooleanInLocalStorage,
} from "../../../utils/StorageSetter.ts";
import { USE_AZURE_KEY } from "../../../utils/StorageConst.ts";

const UseAzureSetting = () => {
  const theme = useTheme();

  const [useAzure, setUseAzure] = useState<boolean>(
    getBooleanFromLocalStorage(USE_AZURE_KEY) || true
  );

  function handleChange() {
    setBooleanInLocalStorage(USE_AZURE_KEY, !useAzure);
    setUseAzure(!useAzure);
  }
  return (
    <>
      <FormControlLabel
        control={
          <Switch checked={useAzure} onChange={handleChange} name="gilad" />
        }
        label="Use Azure for Audio and Lip Sync"
      />
    </>
  );
};

export default UseAzureSetting;
