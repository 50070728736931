class Piece {
  constructor(size, name = "No name") {
    this.size = size;
    this.name = name;
    this.hit = 0;
    this.destroyed = false;
    this.placed = [];
    this.direction = 0;
  }

  placeNew(index) {
    if (this.direction === 0 && this.placed.length === 0) {
      this.placed.push(index);
      return true;
    }
    else if (this.placed.length >= this.size) {
      return false;
    } 
    else if (this.direction === 0 && this.placed.length === 1) {
      if (Math.abs(this.placed - index) === 1) {
        this.direction = 1;
        this.placed.push(index);
        this.placed = this.placed.sort((a, b) => a - b);
        return true;
      }
      else if (Math.abs(this.placed[0] - index) === 10) {
        this.direction = 2;
        this.placed.push(index);
        this.placed = this.placed.sort((a, b) => a - b);
        return true;
      }
      return false;
    }
    else if (this.direction === 1) {
      if (Math.abs(index - this.placed[0]) === 1 || Math.abs(index - this.placed[this.placed.length - 1]) === 1) {
        this.placed.push(index);
        this.placed.sort((a, b) => a - b);
        return true;
      }
      return false;
    }
    else if (this.direction === 2) {
      if (Math.abs(index - this.placed[0]) === 10 || Math.abs(index - this.placed[this.placed.length - 1]) === 10) {
        this.placed.push(index);
        this.placed.sort((a, b) => a - b);
        return true;
      }
      return false;
    }
  }

  hitAndDamaged() {
    this.hit += 1;
    this.destroyed = this.hit >= this.size;
    return [this.destroyed, this];
  }
}
export default Piece;