import {
  Box,
  Button,
  Table,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import ModalCard, { ModalCardProps } from "./ModalCard.tsx";
import React from "react";
import { styles } from "../../../types.tsx";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { ProjectCard } from "../../Projects/ProjectContainer.tsx";
import {
  CHATBOT_COVER_IMG,
  TEMUS_CAROUSEL_1_IMG,
  TEMUS_CAROUSEL_2_IMG,
  TEMUS_CAROUSEL_3_IMG,
  TEMUS_MEDIA_1_IMG,
  TEMUS_MEDIA_2_IMG,
  TEMUS_MEDIA_3_IMG,
} from "../../../assets/index.tsx";

const ModalTemus: React.FC<ModalCardProps> = () => {
  const theme = useTheme();
  const handleDownload = () => {
    const fileName = "Temus_Dylan Letter of Recommendation.pdf";
    const filePath = `${process.env.PUBLIC_URL}/${fileName}`;
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 5,
          overflowY: "scroll",
          ...styles.scrollbar,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 3,
            overflowX: "scroll",
            ...styles.scrollbar,
            minHeight: "60%",
            scrollSnapType: "x proximity",
          }}
        >
          <img
            src={TEMUS_CAROUSEL_1_IMG}
            style={{ height: "80%", scrollSnapAlign: "center" }}
          />
          <img
            src={TEMUS_CAROUSEL_2_IMG}
            style={{ height: "80%", scrollSnapAlign: "center" }}
          />
          <img
            src={TEMUS_CAROUSEL_3_IMG}
            style={{ height: "80%", scrollSnapAlign: "center" }}
          />
          {/* <img
            src="https://placehold.co/600x400"
            style={{ height: "80%", scrollSnapAlign: "center" }}
          />
          <img
            src="https://placehold.co/600x400"
            style={{ height: "80%", scrollSnapAlign: "center" }}
          />
          <img
            src="https://placehold.co/600x400"
            style={{ height: "80%", scrollSnapAlign: "center" }}
          /> */}
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Typography variant="h1">Temus</Typography>
          <Table sx={{ width: "80%" }}>
            <TableRow>
              <TableCell>
                <Typography variant="h3">Position:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h3">AI and ML Engineer Intern</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="h3">Duration:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h3">January 2024 - April 2024</Typography>
              </TableCell>
            </TableRow>
          </Table>
          <Typography variant="h2">Experience</Typography>
          <Typography variant="h6" sx={{ width: "90%" }}>
            <ol>
              <li>
                Technical Contributions: Spearheaded the development of an
                advanced chatbot using GPT-4 and Gemini, integrating real-time
                data streaming. Implemented core features like dynamic chart
                generation and enhanced chat agents with personalities.
              </li>
              <li>
                Skill Development: Mastered prompt engineering, enabling the
                chat agent to provide nuanced analysis and handle unstructured
                data, demonstrating deep understanding of generative AI.
              </li>
              <li>
                User Interface Design: Designed and refined a bespoke user
                interface based on feedback, significantly improving usability
                and aesthetic appeal.
              </li>
              <li>
                Interdisciplinary Collaboration: Worked closely with the HR team
                to commercialize the product, contributing valuable insights to
                shape product strategy and customize features for diverse client
                needs.
              </li>
              <li>
                Team Spirit and Innovation: Exhibited a curious and innovative
                outlook, consistently exploring new solutions and applying
                theoretical knowledge in practical settings.
              </li>
            </ol>
          </Typography>
        </Box>
        <Typography variant="h2">Media</Typography>
        <ProjectCard
          title="DSTA Brainhack 2024"
          description="Experience Sharing"
          image={TEMUS_MEDIA_3_IMG}
          imageLeft={true}
          redictectURL={
            "https://www.linkedin.com/feed/update/urn:li:activity:7206581618864517121/"
          }
        />
        <ProjectCard
          title="Google Cloud deepens its commitment to encourage generative AI development in Singapore"
          description="Trailblazers media coverage"
          image={TEMUS_MEDIA_1_IMG}
          imageLeft={false}
          redictectURL={
            "https://www.theedgesingapore.com/amp/digitaledge/digital-economy/google-cloud-deepens-its-commitment-encourage-generative-ai-development"
          }
        />
        <ProjectCard
          title="#TemusHighlights: January 2024"
          description="Google Cloud Booth"
          image={TEMUS_MEDIA_2_IMG}
          imageLeft={true}
          redictectURL={
            "https://www.linkedin.com/feed/update/urn:li:activity:7158710741703815168/"
          }
        />
        <Button
          sx={{
            backgroundColor: theme.palette.primary.dark,
            gap: 2,
            padding: 1,
          }}
          disableRipple
          variant="contained"
          onClick={handleDownload}
        >
          <Typography sx={{ color: theme.palette.primary.light }}>
            Download Letter of Recommendation
          </Typography>
          <InsertDriveFileOutlinedIcon />
        </Button>
      </Box>
    </>
  );
};

export default ModalTemus;
