import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import IconBadgeWithText from "../IconBadgeWithText.tsx";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import { GitHub, LinkedIn } from "@mui/icons-material";
import { EMAIL } from "../../utils/DefaultConst.ts";

const ContactButtons = () => {
  const theme = useTheme();

  const handleEmail = (event) => {
    event.stopPropagation(); 
    const subject = "Lets connect!";
    const body = "Hey Dylan, I am interested in connecting with you."; 
    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${EMAIL}&su=${subject}&body=${body}`;
    console.log(mailtoLink);
    window.location.href = mailtoLink;
  };

  const handleLinkedIn = (event) => {
    event.stopPropagation(); 
    window.location.href = "https://www.linkedin.com/in/dylan-siew-23294b237/"
  }

  const handleGitHub = (event) => {
    event.stopPropagation(); 
    window.location.href = "https://github.com/dylansiew"
  }
  return (
    <Box
      sx={{
        width: "50%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 5,
      }}
    >
      <Typography variant="h2">Talk to me</Typography>
      <IconBadgeWithText
        width={"50%"}
        icon={EmailOutlinedIcon}
        onClick={handleEmail}
      >
        <Typography variant="h2">Email</Typography>
        <Typography variant="h6" sx={{ color: "grey" }}>
          {EMAIL}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Connect with me</Typography>
          <ArrowRightAltOutlinedIcon />
        </Box>
      </IconBadgeWithText>
      <IconBadgeWithText width={"50%"} icon={LinkedIn} onClick={handleLinkedIn}>
        <Typography variant="h2">LinkedIn</Typography>
        <Typography variant="h6" sx={{ color: "grey" }}>
          Dylan Siew
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Connect with me</Typography>
          <ArrowRightAltOutlinedIcon />
        </Box>
      </IconBadgeWithText>
      <IconBadgeWithText width={"50%"} icon={GitHub} onClick={handleGitHub}>
        <Typography variant="h2">GitHub</Typography>
        <Typography variant="h6" sx={{ color: "grey" }}>
          @dylansiew
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Connect with me</Typography>
          <ArrowRightAltOutlinedIcon />
        </Box>
      </IconBadgeWithText>
    </Box>
  );
};

export default ContactButtons;
