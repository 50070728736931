import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { VOICE_KEY } from "../../../utils/StorageConst.ts";
import { DEFAULT_VOICE } from "../../../utils/DefaultConst.ts";
import VoiceSetting from "../SettingAttributes/VoiceSelection.tsx";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import UseAzureSetting from "../SettingAttributes/UseAzure.tsx";
import RestartChatbotSetting from "../SettingAttributes/RestartChatbot.tsx";
import { GoogleLogoutButton } from "../Authentication/GoogleOAuthButton.jsx";

const ChatSettingButton = ({ handleModalOpen }) => {
  const theme = useTheme();

  return (
    <IconButton onClick={handleModalOpen}>
      <SettingsOutlinedIcon
        sx={{ color: theme.palette.primary.light }}
        fontSize="large"
      />
    </IconButton>
  );
};
const ChatSettingModal = ({ modalVisible, handleModalClose }) => {
  const theme = useTheme();

  return (
    <Modal
      open={modalVisible}
      onClose={handleModalClose}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          height: "80vh",
          width: "80vh",
          backgroundColor: theme.palette.primary.light,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 5,
        }}
      >
        <FormControl sx={{ width: "80%", marginTop: 5, gap: 5 }}>
          {/* <VoiceSetting /> */}
          <UseAzureSetting />
          <RestartChatbotSetting />
          <GoogleLogoutButton handleModalClose={handleModalClose}/>
        </FormControl>
      </Box>
    </Modal>
  );
};

export { ChatSettingModal, ChatSettingButton };
