import {
  Box,
  Table,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import ModalCard, { ModalCardProps } from "./ModalCard.tsx";
import React from "react";
import { styles } from "../../../types.tsx";

const ModalShopee: React.FC<ModalCardProps> = () => {
  const theme = useTheme();

  return (
      <>
        <Box
          sx={{
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowY: "scroll",
            scrollSnapType: "y mandatory",
            gap: 5,
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          <img
            src="https://placehold.co/600x400"
            style={{ width: "80%", scrollSnapAlign: "center" }}
          />
          <img
            src="https://placehold.co/600x400"
            style={{ width: "80%", scrollSnapAlign: "center" }}
          />
          <img
            src="https://placehold.co/600x400"
            style={{ width: "80%", scrollSnapAlign: "center" }}
          />
          <img
            src="https://placehold.co/600x400"
            style={{ width: "80%", scrollSnapAlign: "center" }}
          />
          <img
            src="https://placehold.co/600x400"
            style={{ width: "80%", scrollSnapAlign: "center" }}
          />
          <img
            src="https://placehold.co/600x400"
            style={{ width: "80%", scrollSnapAlign: "center" }}
          />
        </Box>
        <Box
          sx={{
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            overflowY: "scroll",
            ...styles.scrollbar,
          }}
        >
          <Typography variant="h1">Shopee Singapore</Typography>
          <Table sx={{ width: "80%" }}>
            <TableRow>
              <TableCell>
                <Typography variant="h3">Position:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h3">Profit and Loss Intern</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="h3">Duration:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h3">April 2024 - Present</Typography>
              </TableCell>
            </TableRow>
          </Table>
          <Typography variant="h2">Experience</Typography>
          <Typography variant="h6" sx={{ width: "90%" }}>
            <ol>
              <li>
                Initiated a strategic project to integrate Machine Learning and
                Artificial Intelligence capabilities into the Shopee
                International Platform, enhancing the department's technological
                sophistication.
              </li>
              <li>
                Architected and implemented a robust system utilizing Large
                Language Models (LLMs) for the detection of counterfeit and
                hazardous goods, significantly improving platform security and
                compliance.
              </li>
              <li>
                Trained and deployed five advanced machine learning models using
                text embeddings to classify products accurately, leading to more
                efficient and accurate product categorization.
              </li>
              <li>
                Conducted comprehensive analyses and presented detailed reports
                on country-level business contexts, explaining fluctuations in
                profit indicators and providing actionable insights.{" "}
              </li>
              <li>
                Recommended strategic business adjustments based on pricing
                input changes, meticulously measuring and presenting the
                business impacts to management, highlighting the pros and cons
                to inform decision-making.{" "}
              </li>
            </ol>
          </Typography>
        </Box>
      </>
  );
};

export default ModalShopee;
