import { Canvas } from "@react-three/fiber";
import { Experience } from "./AvatarSection/Experience.jsx";
import { Box, Typography, useTheme } from "@mui/material";
import ControlCard from "./AvatarSection/ControlUI.tsx";
import { useContext, useState } from "react";
import {
  ChatSettingModal,
  ChatSettingButton,
} from "./AvatarSection/Settings.tsx";
import { UserContext } from "../../utils/Context/GoogleAuthContext.js";
import { GoogleLoginButton } from "./Authentication/GoogleOAuthButton.jsx";
import LoginPopUp from "./Authentication/LoginPopup.tsx";

function AvatarCard() {
  const [modalVisible, setModalVisible] = useState(false);
  const { user } = useContext(UserContext);

  function handleModalClose() {
    setModalVisible(false);
  }

  function handleModalOpen() {
    setModalVisible(true);
  }

  
  return (
    <>
    {
      !user && <LoginPopUp />
    }
      <Box sx={{ position: "absolute", right: 20, top: 20, zIndex: 6 }}>
        <ChatSettingButton handleModalOpen={handleModalOpen} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: 5,
          width: "100%",
          borderRadius: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <ControlCard />
      </Box>
      <Canvas
        shadows
        camera={{ position: [0, -5, 0], fov: 10 }}
        style={{
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Experience />
      </Canvas>

      <ChatSettingModal
        modalVisible={modalVisible}
        handleModalClose={handleModalClose}
      />
    </>
  );
}

export default AvatarCard;
