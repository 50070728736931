import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { ArrowDownwardRounded } from "@mui/icons-material";
const HeroScrollDownButton = () => {
  return (
    <Box sx={{ flexDirection: "row", display: "flex", gap: 1, color: "grey" }}>
      <Typography>scroll down</Typography>
      <ArrowDownwardRounded />
    </Box>
  );
};

export default HeroScrollDownButton;
