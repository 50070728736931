import { Box, Typography, useTheme, Modal } from "@mui/material";
import React, { ReactElement, ReactNode, useState } from "react";

export interface ModalCardProps {
  open?: boolean;
  handleClose?: (event: any) => void;
  children?: ReactElement;
}
const ModalCard: React.FC<ModalCardProps> = ({
  open,
  handleClose,
  children,
}) => {
  const theme = useTheme();

  if (!open || !handleClose) {
    return;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "80vw",
          height: "80vh",
          backgroundColor: theme.palette.primary.light,
          borderRadius: 5,
          padding: 3,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {children != null ? children : <Typography>Error</Typography>}
      </Box>
    </Modal>
  );
};

export default ModalCard;
