import { Typography, Box, alpha, Button } from '@mui/material';
import React from 'react';
import { maintheme } from '../../../types.tsx';
import CloseIcon from '@mui/icons-material/Close';
const Piece = ({
    piece,
    backgroundColor,
    setSelectedShip,
    disabled, selectedShip
}) => {
    return (
        <Button sx={{ maxWidth: '10vw', width: 'auto', margin: 1, borderStyle: 'solid', padding: 1, borderRadius: 3, borderColor: alpha(maintheme.palette.primary.dark, 0.5), display: 'flex', alignItems: 'center', flexDirection: 'column', backgroundColor: backgroundColor, borderWidth: piece === selectedShip ? 5 : 0 }} disableRipple
            onClick={() => {
                setSelectedShip(piece);
            }}
            disabled={disabled}>
            <Typography sx={{
                color: maintheme.palette.primary.light
            }}>{piece.name}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: 'auto', justifyContent: 'center' }}>
                <img src={require(`../../../Projects/BattleShip/img/${piece.name}.png`)} style={{ width: '40%', filter: piece.destroyed ? 'grayscale(100%)' : 'none', }} /><Typography sx={{
                color: maintheme.palette.primary.light, ml: 2
            }}> x {piece.size}</Typography>
                {piece.destroyed && (<CloseIcon sx={{ color: 'red', fontSize: 'clamp(20px, 8vw, 100px)', position: 'absolute', }} />)}
            </Box> 
        </Button>
    )
}

export default Piece;