import Piece from "./Piece.js";
export default class Pieces {
    constructor() {
      this.pieces = [
        new Piece(5, "Carrier"),
        new Piece(4, "Battleship"),
        new Piece(3, "Cruiser"),
        new Piece(3, "Submarine"),
        new Piece(2, "Destroyer"),
      ];
    }
  
    remove(name) {
      this.pieces = this.pieces.filter(piece => piece.name !== name);
    }
  
    allKilled() {
      return this.pieces.length === 0;
    }
  }
