import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import ButtonContainer from "./ButtonContainer.tsx";
import Title from "../Title.tsx";

const Header = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        padding: 3,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "30%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Title word1={"dylan"} word2={"siew"} variant={"h2"} />
      </Box>
      <Box
        sx={{
          width: "70%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonContainer />
      </Box>
    </Box>
  );
};

export default Header;
