import { Box, Button, Fab, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import IconBadgeWithText from "../IconBadgeWithText.tsx";

const AboutMeText = () => {
  const theme = useTheme();
  const handleDownload = () => {
    const fileName = 'DylanSiewCV.pdf'; 
    const filePath = `${process.env.PUBLIC_URL}/${fileName}`;
    const link = document.createElement('a');
    link.href = filePath;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Box
      sx={{
        width: "100%",
        padding: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: 5,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 5,
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        }}
      >
        <IconBadgeWithText icon={WorkspacePremiumOutlinedIcon} width={"25%"}>
          <Typography variant="h5" color={theme.palette.primary.dark}>
            Experience
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.dark}>
            3+ years
          </Typography>
        </IconBadgeWithText>
        <IconBadgeWithText icon={CloudDoneOutlinedIcon} width={"25%"}>
          <Typography variant="h5" color={theme.palette.primary.dark}>
            Completed
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.dark}>
            10+ projects
          </Typography>
        </IconBadgeWithText>
        <IconBadgeWithText icon={RocketLaunchOutlinedIcon} width={"25%"}>
          <Typography variant="h5" color={theme.palette.primary.dark}>
            Launched
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.dark}>
            3+ products
          </Typography>
        </IconBadgeWithText>
      </Box>
      <Box>
        <Typography variant="h3">
          I develop products that create a significant and positive impact on
          modern society, firmly believing that the application of AI should
          benefit everyone.
        </Typography>
      </Box>
      <Button
        sx={{ backgroundColor: theme.palette.primary.dark, gap: 2, padding: 1 }}
        disableRipple
        variant="contained"
        onClick={handleDownload}
      >
        <Typography sx={{ color: theme.palette.primary.light }}>
          Download CV
        </Typography>
        <InsertDriveFileOutlinedIcon />
      </Button>
    </Box>
  );
};

export default AboutMeText;
