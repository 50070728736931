import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

const Title = ({ word1, word2, variant }) => {
  const theme = useTheme();

  return (
    <Typography
      variant={variant}
      sx={{
        flexDirection: "row",
        display: "flex",
      }}
    >
      <Typography
        variant={variant}
        sx={{
          color: theme.palette.primary.main,
        }}
      >
        {word1}
      </Typography>
      {word2}
    </Typography>
  );
};

export default Title;
