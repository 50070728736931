import { Box, Typography, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { PANTRYPAL_DEMO_VID } from "../../assets/index.tsx";

const VideoSection = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "30%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 5,
        gap: 5,
        position: "fixed",
        height: "100%",
        backgroundColor: "#FF7622",
      }}
    >
      <Typography variant="h2" sx={{color: theme.palette.primary.light}}>PantryPals Demo Video</Typography>
      <video
        ref={videoRef}
        height="80%"
        controls
        autoPlay
        muted={false}
        style={{ borderRadius: 10 }}
        loop
      >
        <source src={PANTRYPAL_DEMO_VID} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default VideoSection;
