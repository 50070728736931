import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Viseme } from '../../types.ts';

// Define the type for the viseme context
interface VisemeContextType {
  visemes: Viseme[];
  setVisemes: React.Dispatch<React.SetStateAction<Viseme[]>>;
  addViseme: (viseme: Viseme) => void;
  removeViseme: (index: number) => void;
}

// Create the context
const VisemeContext = createContext<VisemeContextType | undefined>(undefined);

// Custom hook for accessing the viseme context
export const useVisemes = (): VisemeContextType => {
  const context = useContext(VisemeContext);
  if (!context) {
    throw new Error('useVisemes must be used within a VisemeProvider');
  }
  return context;
};

interface VisemeProviderProps {
  children: ReactNode;
}

export const VisemeProvider: React.FC<VisemeProviderProps> = ({ children }) => {
  const [visemes, setVisemes] = useState<Viseme[]>([]);

  const addViseme = (viseme: Viseme) => {
    setVisemes(prevVisemes => [...prevVisemes, viseme]);
  };

  const removeViseme = (index: number) => {
    setVisemes(prevVisemes => prevVisemes.filter((_, i) => i !== index));
  };

  return (
    <VisemeContext.Provider value={{ visemes, setVisemes, addViseme, removeViseme }}>
      {children}
    </VisemeContext.Provider>
  );
};
