import React, { useState, useEffect, useContext } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { UserContext } from "../../../utils/Context/GoogleAuthContext.js";
import { Button, Typography, useTheme } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

export function GoogleLoginButton() {
  const [user, setInternalUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const { setUser } = useContext(UserContext);
  const theme = useTheme();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setInternalUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user && user.access_token) {
      // console.log(user.access_token)
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          const newUser = {
            name: res.data.name,
            user_name: res.data.email,
            avatar: res.data.picture,
          };
          setUser(newUser);
        })
        .catch((err) => {console.log(err);googleLogout();
          setUser(null);});
    }
  }, [user]);

  return (
    <Button
      sx={{
        backgroundColor: theme.palette.primary.dark,
        gap: 2,
        padding: 1,
      }}
      disableRipple
      variant="contained"
      onClick={login}
    >
      <Typography sx={{}}>Sign in with Google</Typography>
      <GoogleIcon />
    </Button>
  );
}

export function GoogleLogoutButton({ handleModalClose }) {
  const { setUser } = useContext(UserContext);
  const theme = useTheme();

  const logOut = () => {
    googleLogout();
    setUser(null);
    handleModalClose();
  };

  return (
    <Button
      sx={{
        backgroundColor: theme.palette.primary.dark,
        gap: 2,
        padding: 1,
      }}
      disableRipple
      variant="contained"
      onClick={logOut}
    >
      <Typography sx={{}}>Sign out</Typography>
      <GoogleIcon />
    </Button>
  );
}
