import { Html, OrbitControls, useTexture } from "@react-three/drei";
import { Avatar } from "./Avatar";
import { useThree } from "@react-three/fiber";
import { Box, Button, TextField, useTheme } from "@mui/material";

export const Experience = () => {
  const texture = useTexture("textures/office.jpg");

  const viewport = useThree((state) => state.viewport);

  return (
    <>
      {/* <OrbitControls /> */}


      <group position={0}>
        <Avatar />
      </group>

      <ambientLight intensity={2.5} />
      <mesh rotation={[Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
        <planeGeometry args={[viewport.width, viewport.height]} />
        <meshBasicMaterial map={texture} />
      </mesh>
    </>
  );
};
