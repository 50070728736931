import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Box, Typography, useTheme } from "@mui/material";
import {
  NTU_LOGO_IMG,
  NUS_LOGO_IMG,
  RAFFLES_LOGO_IMG,
} from "../../assets/index.tsx";
import ChildFriendlyOutlinedIcon from "@mui/icons-material/ChildFriendlyOutlined";
const EducationContainer = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100%",
      }}
    >
      <Typography variant="h2">Education</Typography>
      <Timeline
        position="alternate-reverse"
        sx={{ alignItems: "flex-start", width: "70%" }}
      >
        <TimelineItem sx={{ width: "100%" }}>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  maxWidth: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={NTU_LOGO_IMG} style={{ width: "150%" }} />
              </Box>
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  sx={{ fontSize: theme.typography.h2, textAlign: "left" }}
                >
                  Nanyang Technological University
                </Typography>
                <Typography sx={{ textAlign: "left", color: "grey" }}>
                  Computer Science and Economics Double Degree
                </Typography>
                <Typography sx={{ textAlign: "left", color: "grey" }}>
                  2022 - 2027 (Expected)
                </Typography>
                <Typography sx={{ textAlign: "left", color: "grey" }}>
                  2 x Dean's List
                </Typography>
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ width: "100%" }}>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography
                  sx={{ fontSize: theme.typography.h2, textAlign: "right" }}
                >
                  National University of Singapore
                </Typography>
                <Typography sx={{ textAlign: "right", color: "grey" }}>
                  Singapore Universities Student Exchange Programme
                </Typography>
                <Typography sx={{ textAlign: "right", color: "grey" }}>
                  January 2024 - May 2024
                </Typography>
              </Box>
              <Box
                sx={{
                  maxWidth: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={NUS_LOGO_IMG} style={{ width: "100%" }} />
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ width: "100%" }}>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  maxWidth: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={RAFFLES_LOGO_IMG} style={{ width: "55%" }} />
              </Box>
              <Box
                sx={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  sx={{ fontSize: theme.typography.h2, textAlign: "left" }}
                >
                  Raffles Junior College
                </Typography>
                <Typography sx={{ textAlign: "left", color: "grey" }}>
                  Physics, Chemistry, Mathematics, Economics
                </Typography>
                <Typography sx={{ textAlign: "left", color: "grey" }}>
                  2014 - 2019
                </Typography>
                <Typography sx={{ textAlign: "left", color: "grey" }}>
                  Straight As
                </Typography>
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ width: "100%" }}>
          <TimelineSeparator>
            <ChildFriendlyOutlinedIcon
              fontSize="large"
              sx={{ color: "grey" }}
            />
          </TimelineSeparator>
          <TimelineContent>
            <Box>
              <Typography
                sx={{ fontSize: theme.typography.h2, textAlign: "left" }}
              >
                KK Women's and Children's Hospital
              </Typography>
            </Box>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
};

export default EducationContainer;
