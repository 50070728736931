import React, { useState, useRef } from 'react';
import { Typography, Box, alpha, Button } from '@mui/material';
import { maintheme } from "../../../types.tsx";
const GridCell = ({ handleSelect, index, cellShip, piece}) => {
    return (

        <Button
            key={index}
            style={{
                border: "1px solid black",
                width: "100%",
                height: "100%",
                aspectRatio: 1,
                background:
                piece != null
                        ? maintheme.palette.primary.main
                        : "white",
                cursor: "pointer",
                borderRadius: 0
            }}
            onClick={() => {handleSelect(index)}}
            disableRipple
        >
            {piece != null && (<img src={require(`../../../Projects/BattleShip/img/${piece.name}.png`)} style={{ width: '70px' }}></img>
            )}
        </Button>)

}

export default GridCell;