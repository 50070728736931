import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import IconBadgeWithText from "../IconBadgeWithText.tsx";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import ContactButtons from "./ContactButtons.tsx";
import ContactForm from "./ContactForm.tsx";

const ContactContainer = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
     <ContactButtons />
     <ContactForm />
    </Box>
  );
};

export default ContactContainer;
