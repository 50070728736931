import { Typography, Box, alpha, Button } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useGame } from '../logic/GameContext';
import { maintheme } from '../../../types.tsx';
import CloseIcon from '@mui/icons-material/Close';
import Piece from './Piece.tsx';
import HeatMap from './Heatmap.js';
import Gamegrid from './Gamegrid.jsx'
const PieceContainter = ({children, setSelectedShip, selectedShip}) => {
    const { player, opponent, handleAction, setPlayer, setOpponent } = useGame();
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end'
            }}>
                <Box sx={{ minWidth: '10vw', width: 'auto', margin: 1, borderStyle: 'solid', padding: 1, borderRadius: 3, borderColor: alpha(maintheme.palette.primary.dark, 0.5), display: 'flex', alignItems: 'center', flexDirection: 'column', backgroundColor: '#0090ab' }}>
                    <Typography sx={{
                        color: maintheme.palette.primary.dark,
                    }}>Player</Typography>
                </Box>
                {
                    player.pieces.map((piece) => (
                        <Piece key={piece.name} piece={piece} backgroundColor={'#0090ab'} setSelectedShip={setSelectedShip} disabled={false} selectedShip={selectedShip}/>
                    ))
                }
            </Box>
            {children}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                mt: 3
            }}>
                <Box sx={{ minWidth: '10vw', width: 'auto', margin: 1, borderStyle: 'solid', padding: 1, borderRadius: 3, borderColor: alpha(maintheme.palette.primary.dark, 0.5), display: 'flex', alignItems: 'center', flexDirection: 'column', backgroundColor: '#db4646' }}>
                    <Typography sx={{
                        color: maintheme.palette.primary.dark,                    }}>Opponent</Typography>
                </Box>
                {
                    opponent.pieces.map((piece) => (
                        <Piece key={piece.name} piece={piece} backgroundColor={'#db4646'} setSelectedShip={setSelectedShip} disabled={true} selectedShip={selectedShip}/>
                    ))
                }
            </Box>
        </Box>
    )
}

export default PieceContainter;