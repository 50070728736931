import {
  Box,
  Button,
  IconButton,
  Input,
  InputAdornment,
  LinearProgress,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useTextToSpeech from "../../../hooks/textToSpeech/index.jsx";
import useSpeechToText from "../../../hooks/speechToText/index.jsx";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { chatbot_query } from "../../../utils/api.ts";
import { getAudioFromDB } from "../../../utils/database.ts";
import { getBooleanFromLocalStorage } from "../../../utils/StorageSetter.ts";
import { USE_AZURE_KEY } from "../../../utils/StorageConst.ts";
import { UserContext } from "../../../utils/Context/GoogleAuthContext.js";
import { useAudio } from "../../../utils/Context/AudioContext.tsx";
import { useVisemes } from "../../../utils/Context/VisemeContext.tsx";

const ControlCard = () => {
  const theme = useTheme();
  const { start, stop, isReading } = useTextToSpeech();
  const [inputText, setInputText] = useState<string>("");
  const {
    isListening,
    transcript,
    startListening,
    stopListening,
    resetTranscript,
  } = useSpeechToText({ continuous: true });
  const { setAudio } = useAudio();
  const { user } = useContext(UserContext);
  const { setVisemes } = useVisemes();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [combinedText, setCombinedText] = useState("");
  const [question, setQuestion] = useState("");
  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());
  const [notified, setNotified] = useState(false);

  useEffect(() => {
    if (user) {
      setQuestion(`Hey ${user.name}, I am Timothy! Let's talk`);
    } else {
      setQuestion("Error");
    }
  }, [user]);
  const [isTalking, setIsTalking] = useState(false);
  const [isQuerying, setIsQuerying] = useState(false);
  const startStopListening = () => {
    isListening ? stopVoiceInput() : startListening();
  };

  useEffect(() => {
    setCombinedText(
      isListening
        ? inputText +
            (transcript.length
              ? (inputText.length ? " " : "") + transcript
              : "")
        : inputText
    );
  }, [isListening, inputText, transcript, transcript.length]);
  useEffect(() => {
    setLastUpdateTime(Date.now());
    setNotified(false);
  }, [combinedText]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!notified && isListening && Date.now() - lastUpdateTime >= 3000) {
        handleSendPress();
        setNotified(true);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastUpdateTime, notified, isListening]);
  const stopVoiceInput = () => {
    setInputText(
      (prevVal) =>
        prevVal +
        (transcript.length ? (prevVal.length ? " " : "") + transcript : "")
    );
    stopListening();
  };

  const handleSendPress = async () => {
    try {
      if (combinedText == "" || combinedText.length === 0) return;
      setIsQuerying(true);
      const responseText = await chatbot_query(combinedText);
      console.log(responseText)
      setIsQuerying(false);
      setQuestion(responseText.subtitle);
      setInputText("");
      setCombinedText("");
      if (responseText.viseme) setVisemes(responseText.viseme);
      if (isTalking) return;
      setIsTalking(true);
      const base64 = await getAudioFromDB();
      if (base64) {
        const audio = new Audio(`data:audio/wav;base64,${base64}`);
        setAudio(audio);
      }

      setIsTalking(false);
    } catch (error) {
      setIsQuerying(false);

      console.error("Error fetching or playing audio:", error);
    }
  };

  return (
    <Box
      sx={{
        width: "50%",
        height: "25%",
        backgroundColor: "rgba(255,255,255,0.5)",
        marginBottom: 10,
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        gap: 2,
        backdropFilter: "blur(20px)",
        WebkitBackdropFilter: "blur(10px)",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "10px", // Adjust width of the scrollbar
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: theme.palette.primary.main, // Adjust scrollbar thumb color
          borderRadius: "10px", // Rounded corners for the scrollbar thumb
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // Transparent background for the scrollbar track
          borderRadius: "10px", // Rounded corners for the scrollbar track
        },
      }}
    >
      <Box sx={{}}>
        <Typography variant="h2" sx={{ color: theme.palette.primary.dark }}>
          {question}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "60%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {isQuerying ? (
          <>
            <LinearProgress
              sx={{ color: theme.palette.primary.main, width: "90%" }}
            />
          </>
        ) : (
          <Input
            sx={{
              width: "90%",
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: theme.palette.primary.dark,
              },
              "&::-webkit-scrollbar": {
                width: "12px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: theme.palette.primary.main,
                borderRadius: "10px",
              },
              height: "100%",
            }}
            inputProps={{
              style: { fontSize: 25, color: theme.palette.primary.dark },
            }}
            maxRows={5}
            multiline
            value={combinedText}
            disabled={isListening}
            onChange={(e) => {
              setInputText(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={startStopListening}>
                  {isListening ? (
                    <KeyboardVoiceOutlinedIcon
                      sx={{ color: theme.palette.primary.main }}
                    />
                  ) : (
                    <MicOffOutlinedIcon
                      sx={{ color: theme.palette.primary.main }}
                    />
                  )}
                </IconButton>
                <IconButton
                  disabled={inputText.length == 0}
                  onClick={handleSendPress}
                >
                  <SendOutlinedIcon
                    sx={{
                      color:
                        inputText.length == 0
                          ? theme.palette.primary.light
                          : theme.palette.primary.main,
                    }}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default ControlCard;
