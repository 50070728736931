import { Typography } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { GameProvider } from '../logic/GameContext';
import PieceContainter from './PieceContainer.tsx';
import Gammegrid from './Gamegrid.tsx';


const GamePage = () => {
    const [selectedShip, setSelectedShip] = useState(null);
    return (
        <GameProvider>
            <PieceContainter setSelectedShip={setSelectedShip} selectedShip={selectedShip}>
                <Gammegrid selectedShip={selectedShip}></Gammegrid>
            </PieceContainter>
        </GameProvider>

    )
}

export default GamePage;