import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import EducationContainer from "./EducationContainter.tsx";
import SectionHeader from "../SectionHeader.tsx";

const SkillsSection = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100%",
        scrollSnapAlign: "start",
      }}
    >
      <SectionHeader title={"Skills"} subtext={"My technical skills"} />
      <EducationContainer />
    </Box>
  );
};

export default SkillsSection;
