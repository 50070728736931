import { Box, Typography } from "@mui/material";
import React from "react";
import { ATKL_COVER_IMG, CHATBOT_COVER_IMG, PANTRYPAL_COVER_IMG } from "../../assets/index.tsx";

const ProjectCard = ({ title, description, image, imageLeft, redictectURL }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        "&:hover": {
          boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.4)",
        },
        transition: "box-shadow 0.3s ease-in-out",
      }}

      onClick={() => {window.location.href = redictectURL}}
    >
      {imageLeft && (
        <Box
          sx={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 5,
          }}
        >
          <img
            src={image}
            style={{ borderRadius: 20, width: "80%" }}
            alt={title}
          />
        </Box>
      )}

      <Box
        sx={{
          maxWidth: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: imageLeft ? "flex-start" : "flex-end",
          gap: 5,
          padding: 5,
        }}
      >
        <Typography variant="h2">{title}</Typography>
        <Typography variant="h3" sx={{ color: "grey" }}>
          {description}
        </Typography>
      </Box>
      {!imageLeft && (
        <Box
          sx={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 5,
          }}
        >
          <img
            src={image}
            style={{ borderRadius: 20, width: "80%" }}
            alt={title}
          />
        </Box>
      )}
    </Box>
  );
};

const ProjectContainer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 5,
      }}
    >
       <ProjectCard
        title="Chatbot"
        description="AI Avatar Interact offers a 3D avatar with STT, TTS, and lip sync, powered by AI for versatile applications like interviewing bots, customer service, and service training."
        image={CHATBOT_COVER_IMG}
        imageLeft={false}
        redictectURL={"/chatbot"}
      />
      <ProjectCard
        title="PantryPals"
        description="AI-powered grocery management, community food sharing, recipe suggestions, and efficient inventory tracking to minimize waste and support sustainability."
        image={PANTRYPAL_COVER_IMG}
        imageLeft={true}
        redictectURL={"/pantrypals"}

      />
     
      <ProjectCard
        title="ATKL"
        description="ATKL is your go-to app for mastering your coursework, making study sessions more effective and engaging. Say goodbye to generic study aids and hello to personalized learning with ATKL!"
        image={ATKL_COVER_IMG}
        imageLeft={false}
        redictectURL={"/chatbot"}

      />
    </Box>
  );
};

export  {ProjectContainer, ProjectCard};
