export const HERO_AVATAR_IMG = require("./heroavatar.jpg")
export const ABOUT_ME_AVATAR_IMG = require("./aboutmeavatar.jpg")
export const NUS_LOGO_IMG = require("./NUS.jpg")
export const NTU_LOGO_IMG = require("./NTU.png")
export const RAFFLES_LOGO_IMG = require("./Raffles.png")
export const PANTRYPAL_COVER_IMG = require("./Pantrypal.jpg")
export const ATKL_COVER_IMG = require("./ATKL.png")
export const TEMUS_LOGO_IMG = require("./Temus.jpg")
export const QCP_LOGO_IMG = require("./QCP.jpg")
export const SHOPEE_COVER_IMG = require("./Shopee.png")
export const CHATBOT_COVER_IMG = require("./Chatbot.png")
export const TEMUS_MEDIA_1_IMG = require("./TemusMedia1.jpeg")
export const TEMUS_MEDIA_2_IMG = require("./TemusMedia2.jpeg")
export const TEMUS_MEDIA_3_IMG = require("./TemusMedia3.jpeg")
export const TEMUS_CAROUSEL_1_IMG = require("./TemusCarousel1.jpeg")
export const TEMUS_CAROUSEL_2_IMG = require("./TemusCarousel2.jpeg")
export const TEMUS_CAROUSEL_3_IMG = require("./TemusCarousel3.jpeg")
export const QCP_CAROUSEL_1_IMG = require("./QCPCarousel1.jpeg")
export const QCP_CAROUSEL_2_IMG = require("./QCPCarousel2.jpg")
export const PANTRYPAL_DEMO_VID = require("./PantryPalDemo.mp4")
export const PANTRYPAL_ARCH_DIAG_IMG = require("./PantryPalsDiagram.png")