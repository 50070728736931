import { Box, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import SectionHeader from "../SectionHeader.tsx";
import InternshipContainer from "./InternshipContainer.tsx";

const WorkExperienceSection = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100%",
        scrollSnapAlign: "start",
      }}
    >
      <SectionHeader title={"Experience"} subtext={"My working experience"} />
      <InternshipContainer />
    </Box>
  );
};

export default WorkExperienceSection;
