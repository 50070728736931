import * as React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import {
  QCP_LOGO_IMG,
  SHOPEE_COVER_IMG,
  TEMUS_LOGO_IMG,
} from "../../assets/index.tsx";
import IconBadgeWithText from "../IconBadgeWithText.tsx";
import ModalShopee from "./Modal/ModalShopee.tsx";
import ModalCard from "./Modal/ModalCard.tsx";
import ModalTemus from "./Modal/ModalTemus.tsx";
import ModalQCP from "./Modal/ModalQCP.tsx";

const InternshipCard = ({ image, companyName, modalComponent }) => {
  const theme = useTheme();

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const handleClose = (event) => {
    event.stopPropagation(); // Prevents event bubbling
    setModalOpen(false);
  };

  const handleOpen = (event) => {
    event.stopPropagation(); // Prevents event bubbling
    setModalOpen(true);
  };

  return (
    <IconBadgeWithText width={"20%"} onClick={handleOpen}>
      <img src={image} style={{ width: "100%" }} />
      <Box
        sx={{
          width: "100%",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h3" sx={{ color: theme.palette.primary.dark }}>
          {companyName}
        </Typography>
      </Box>
      <ModalCard open={modalOpen} handleClose={handleClose}>
        {modalComponent}
      </ModalCard>
    </IconBadgeWithText>
  );
};

const InternshipContainer = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100%",
        gap: 2,
      }}
    >
      <Typography variant="h2">Internships</Typography>
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          overflowX: "hidden",
          flexDirection: "row",
          padding: 5,
          justifyContent: "space-evenly",
        }}
      >
        <InternshipCard image={SHOPEE_COVER_IMG} companyName={"Shopee"} modalComponent={<ModalShopee />}/>
        <InternshipCard image={TEMUS_LOGO_IMG} companyName={"Temus"} modalComponent={<ModalTemus />}/>
        <InternshipCard image={QCP_LOGO_IMG} companyName={"QCP Singapore"} modalComponent={<ModalQCP />}/>
      </Box>
    </Box>
  );
};

export default InternshipContainer;
