export const visemeMap: { [key: number]: string } = {
  0: "viseme_sil",
  1: "viseme_aa",
  2: "viseme_aa",
  3: "viseme_O",
  4: "viseme_U",
  5: "viseme_CH",
  6: "viseme_RR",
  7: "viseme_U",
  8: "viseme_O",
  9: "viseme_U",
  10: "viseme_O",
  11: "viseme_aa",
  12: "viseme_CH",
  13: "viseme_RR",
  14: "viseme_nn",
  15: "viseme_SS",
  16: "viseme_CH",
  17: "viseme_TH",
  18: "viseme_FF",
  19: "viseme_TH",
  20: "viseme_kk",
  21: "viseme_PP"
};

export const getAvatarViseme = (id: number): string => {
  return visemeMap[id] || "viseme_sil"; 
};